<script setup lang="ts">
import { BoxLayout, DisplayMode } from '@shopware-pwa/composables-next';
import {
  getProductThumbnailUrl,
  getSmallestThumbnailUrl,
} from '@shopware-pwa/helpers-next';
import { Product, ClientApiError } from '@shopware-pwa/types';
import deepMerge from '../../helpers/deepMerge';
import getTranslations from '../../helpers/getTranslations';
import { ProductCard } from '@upa/design-system';
import { CircleButton } from '@upa/design-system';
import { changeCartItemQuantity } from '@shopware-pwa/api-client';
import defaultImg from '~/assets/default-img.png';

const { removeItem, cart, refreshCart, cartItems } = useCart();

const { apiInstance } = useShopwareContext();
const { getFormattedPrice } = usePrice();

const { pushSuccess, pushError } = useNotifications();

const props = withDefaults(
  defineProps<{
    product: Product;
    layoutType?: BoxLayout;
    isProductListing?: boolean;
    displayMode?: DisplayMode;
  }>(),
  {
    layoutType: 'standard',
    displayMode: 'standard',
    isProductListing: false,
  },
);

type Translations = {
  product: {
    addedToWishlist: string;
    reason: string;
    cannotAddToWishlist: string;
    addedToCart: string;
    addToCart: string;
    details: string;
  };
};

let translations: Translations = {
  product: {
    addedToWishlist: 'has been added to wishlist.',
    reason: 'Reason',
    cannotAddToWishlist: 'cannot be added to wishlist.',
    addedToCart: 'has been added to cart.',
    addToCart: 'Add to cart',
    details: 'Details',
  },
};

const globalTranslations = getTranslations();
translations = deepMerge(translations, globalTranslations) as Translations;

const { product } = toRefs(props);

// Declare wishlists handlers  TODO: wishlist after GO LIVE

const { addToWishlist, removeFromWishlist, isInWishlist } =
  useProductWishlist(product);

const toggleWishlistProduct = async () => {
  if (!isInWishlist.value) {
    try {
      await addToWishlist();
      return pushSuccess(
        `${props.product?.translated?.name} ${translations.product.addedToWishlist}`,
      );
    } catch (error) {
      const e = error as ClientApiError;
      const reason = e?.messages?.[0]?.detail
        ? `${translations.product.reason}: ${e?.messages?.[0]?.detail}`
        : '';
      return pushError(
        `${props.product?.translated?.name} ${translations.product.cannotAddToWishlist}\n${reason}`,
        { timeout: 5000 },
      );
    }
  }
  removeFromWishlist();
};

// TODO: product is missing media object, media: null , its needed for   getSmallestThumbnailUrl to work,
// workaround now thumbnails width 500

function getThumbnail500(thumbnails: object) {
  if (!thumbnails || !Array.isArray(thumbnails)) {
    return null; // Return null if the input is invalid
  }

  // Find the thumbnail with a width of 500 (assuming width is a property in each thumbnail object)
  const thumbnail500 = thumbnails.find((thumbnail) => thumbnail.width === 500);
  return thumbnail500?.url || null; // Return the found thumbnail or null if none found
}

const { addToCart, isInCart } = useAddToCart(product);

const productTmp = product.value as any;

const { isProfessionalStore, storeId } = useStore();

let resortCollector = [];
let resort = [];

let resortCollectorMain = [];
let mainResort = ref(false);

/**
 * Looking for Resort Supertitle
 */
for (let i = 0; i < productTmp.properties.length; i++) {
  if (
    productTmp.properties[i]?.group?.translated?.customFields &&
    productTmp.properties[i]?.group?.translated?.customFields
      .show_resort_subtitle_in_product_box &&
    productTmp.properties[i].group.translated.customFields
      .show_resort_subtitle_in_product_box == true
  ) {
    resortCollector.push(productTmp.properties[i].translated.name);

    if (
      productTmp.properties[i].group.translated.customFields
        .group_use_in_frontend_checkbox_main_resort === true
    ) {
      mainResort.value = true;
      resortCollectorMain.push(productTmp.properties[i].translated.name);
    } else {
      resortCollector.push(productTmp.properties[i].translated.name);
    }
  }
}

resort = mainResort.value ? resortCollectorMain[0] : resortCollector[0];

let characteristicsCollector: { icon: string; name: string }[] = [];
let productNumber = productTmp.productNumber;

/**
 * Collecting characteristics from match of options and properties IDs for B2C and B2B and professional- currently wanted: Language & Markt for all
 * custom field: group_use_in_frontend_checkbox_product_card needed for it from Extended Frontend Information
 * why? by default product has 2 options : markt, version and language, we want to display 2 of those: language and markt so they need to have the customField
 */

//TODO: clean code / the old one
// if(storeId === 'stnet-store' || storeId === 'consumer-store' ) {
// if (productTmp?.properties?.length) {
//   for (let i = 0; i < productTmp.properties.length; i++) {
//     if(Object.keys(productTmp.properties[i]?.group?.translated?.customFields) &&
//       Object.keys(productTmp.properties[i].group.translated.customFields).length != 0 &&
//       productTmp.properties[i].group?.translated?.customFields
//         .group_use_in_frontend_checkbox_product_card &&
//       productTmp.properties[i].group?.translated?.customFields
//         .group_use_in_frontend_checkbox_product_card == true ) {

//       if (productTmp?.options?.length) {
//         for (let j = 0; j < productTmp.options.length; j++) {
//           if(productTmp.properties[i]?.id === productTmp.options[j]?.id) {

//             const newCharacteristic = {
//               name: productTmp.properties[i]?.translated?.name,
//               icon: productTmp.properties[i]?.group?.translated?.customFields?.group_use_in_frontend_property_icon_name

//             };
//             characteristicsCollector.push(newCharacteristic);
//           }
//         }
//       }
//     }
//   }
// }

//TODO: clean code
if (productTmp?.options?.length) {
  for (let j = 0; j < productTmp.options.length; j++) {
    if (
      productTmp.options[j]?.group?.customFields &&
      productTmp.options[j]?.group?.customFields
        .group_use_in_frontend_checkbox === true
    ) {
      const newCharacteristic = {
        name: productTmp.options[j]?.translated?.name,
        icon: productTmp.options[j]?.group?.translated?.customFields
          ?.group_use_in_frontend_property_icon_name,
      };

      characteristicsCollector.push(newCharacteristic);
    }

    if (productTmp?.properties?.length) {
      for (let i = 0; i < productTmp.properties.length; i++) {
        if (
          Object.keys(
            productTmp.properties[i]?.group?.translated?.customFields,
          ) &&
          Object.keys(productTmp.properties[i].group.translated.customFields)
            .length != 0 &&
          productTmp.properties[i].group?.translated?.customFields
            ?.group_use_in_frontend_checkbox === false &&
          productTmp.properties[i].group?.translated?.customFields
            ?.group_use_in_frontend_checkbox_product_card == true
        ) {
          if (productTmp.properties[i]?.id === productTmp.options[j]?.id) {
            const newCharacteristic = {
              name: productTmp.properties[i]?.translated?.name,
              icon: productTmp.properties[i]?.group?.translated?.customFields
                ?.group_use_in_frontend_property_icon_name,
            };
            characteristicsCollector.push(newCharacteristic);
          }
        }
      }
    }
  }
}

/**
 * Product Number
 */
if (productNumber && storeId === 'professional-store') {
  const newCharacteristic = {
    icon: 'id',
    name: productNumber,
  };
  characteristicsCollector.push(newCharacteristic);
}

let char = characteristicsCollector;

// productTmp.src = getProductThumbnailUrl(product.value);
//TODO: replace with getSmallestThumbnailUrl when product.media exist
productTmp.src =
  getThumbnail500(product.value.cover?.media?.thumbnails) ||
  product.value.cover?.media?.url ||
  defaultImg;
productTmp.superTitle = resort;
productTmp.stock = product.value.availableStock;
productTmp.issuuLink =
  product.value.translated.customFields.variantenartikel_issuu ||
  product.value.customFields.variantenartikel_issuu;
productTmp.downloadLink =
  product.value.translated.customFields.variantenartikel_download_url ||
  product.value.customFields.variantenartikel_download_url;
productTmp.professionalShop = storeId === 'professional-store';
productTmp.isB2BShop = storeId === 'stnet-store';
productTmp.notB2B =
  storeId === 'consumer-store' || storeId === 'professional-store';
productTmp.inCart = isInCart.value;
productTmp.quantity = product.value.calculatedPrice?.quantity;
// productTmp.quantity = product.value.quantity;
productTmp.maxQty = product.value.calculatedMaxPurchase;
// productTmp.maxQty = product.value.maxPurchase;
productTmp.minQty = product.value.minPurchase;
productTmp.characteristics = char;
productTmp.variants = product.value.options; //TODO: need we variants ????
productTmp.price =
  product.value.calculatedPrice.totalPrice !== 0
    ? getFormattedPrice(product.value.calculatedPrice.totalPrice)
    : '';
productTmp.minStock =
  product.value.translated?.customFields.st_stock_level_notification_min_stock;

const cart2: any = cart;
const ocErrors = computed(() => {
  if (cart2?.value?.errors) return cart2.value.errors;
  else {
    return [];
  }
});

/**
 * openOffcanvas is accessed here.
 * This is adapted here so that the OffcanvasCard opens
 * with toggleAddToCartProxy in CheckoutSideCart.vue.
 */
const openOffcanvas = inject('openOffcanvas') as Ref<boolean>;

const notificationOpen = inject('notificationOpen') as Ref<boolean>;

watch(openOffcanvas, (newValue) => {
  openOffcanvas.value = newValue;
});

/**
 * toggleAddToCartProxy for B2C consumer store STR (st:dev)
 * the addToCart Button adds product to card, shows the added state and removes button from cart (3 functionalities)
 */
const toggleAddToCartProxy = async () => {
  if (storeId == 'consumer-store') {
    if (!isInCart.value) {
      await addToCart();

      if (Object.keys(ocErrors.value).length == 0) {
        // pushSuccess(`${props.product?.translated?.name} ${translations.product.addedToCart}`);
        notificationOpen.value = true;
      }

      // for (const e in ocErrors.value) {
      //   // console.log(typeof e); -STRING TODO: how to access its message ?
      //   pushError(`${props.product?.translated?.name} ${e}`);
      // }
      notificationOpen.value = true;
      openOffcanvas.value = true;
    } else {
      let productIdToRemoveFromCart = { id: productTmp.id };
      // console.log(`remove ${product.value.id} from cart!`);
      await removeItem(productIdToRemoveFromCart as any);
      refreshCart();
    }
  }
};

/**
 * Define a function to handle product quantity changes asynchronously (for QTY Btn in Product Card)
 */

// Define a variable to store the quantity
let collectedQuantity: any = 0;
// This function collects the qty being tiped or incremented/decremented by user in the qty button in Product Card
const handleProductQuantity = async (data: any) => {
  collectedQuantity = data; // Store the entire data received
};

// Helper findCartItemQuantity : it goes through the items in cart and checks with current product id the amount in cart of this product
function findCartItemQuantity(cartItems: any, productId: number) {
  for (let i = 0; i < cartItems.length; i++) {
    if (cartItems[i].id === productId) {
      return cartItems[i].quantity;
    }
  }
  // Return null or handle the case where the product ID is not found in cartItems
  return null;
}

/**
 * toggleAddToCartProxyProfessional
 * for professional shop, here the add to cart btn ONLY adds to cart, doesnt change the state icon and it doesnt remove item from cart
 */
// get the updated collectedQuantity and on add to cart add it or update value in cart. reset to 1 afterwards

const onlyAddToCartProxyProfessionalB2B = async () => {
  // if (storeId === 'professional-store') {
  // FOR GO LIVE B2B goes as Professional with the add to cart logic, after GO LIVE logic with modal variant should be implemented
  // B2B STNET stnet:dev - if time there implement separate logic: open variant and add through variant, if not as professional

  // on every click on circle btn, adds automatically 1 item
  await addToCart();

  // If user used the change qty btn to change qty
  if (collectedQuantity.quantity !== undefined) {
    // it updates the cart item value by taking the user input value from QTY Btn and adding current cart item value - 1
    // "- 1" since with the click on circle btn is always already one being added
    let itemCartAmount =
      collectedQuantity.quantity +
      findCartItemQuantity(cartItems.value, collectedQuantity.productId) -
      1;

    await changeCartItemQuantity(
      collectedQuantity.productId,
      itemCartAmount,
      apiInstance,
    );
    await refreshCart();
  }

  // opens the OC cart
  notificationOpen.value = true;
  openOffcanvas.value = true;

  // TODO: set to 1 at the end of this proxy
  // }
};
</script>

<template>
  <ProductCard
    :product="productTmp"
    :in-cart="isInCart"
    :characteristics="productTmp.characteristics"
    @update:quantity="(quantity) => handleProductQuantity(quantity)"
  >
    <!-- //TODO: after GO LIVE: wishlist functionality in client only  -->
    <!-- <template #toggleToFavoritesLink>
      <client-only>
        <button
          aria-label="Add to wishlist"
          type="button"
          class="absolute bg-transparent top-2 right-2 hover:animate-count-infinite hover:animate-heart-beat"
          data-testid="product-box-toggle-wishlist-button"
          @click="toggleWishlistProduct"
        >
          add to wishlst
        </button>
      </client-only>
    </template> -->

    <template #toggleCartBtn>
      <client-only>
        <!-- // TODO: its for B2C shop -->
        <CircleButton
          :added-to-cart="isInCart ? true : false"
          :add-to-cart="isInCart ? false : true"
          :disabled="product.stock <= 0"
          :icon="isInCart ? 'check' : 'cart'"
          class="product-card-add-to-cart__btn"
          @click="toggleAddToCartProxy"
        />
      </client-only>
    </template>

    <!-- // For now onlyAddToCartProxyProfessionalB2B covers also B2B, after GO LIVE it should have separate logic with opening variant modal  -->
    <template #toggleCartBtnProfessional>
      <client-only>
        <CircleButton
          :added-to-cart="false"
          :add-to-cart="true"
          :disabled="product.stock <= 0"
          icon="cart"
          :class="
            storeId === 'professional-store' || storeId === 'stnet-store'
              ? 'is-professional-shop'
              : ''
          "
          class="product-card-add-to-cart__btn"
          @click="onlyAddToCartProxyProfessionalB2B"
        />
      </client-only>
    </template>
  </ProductCard>
</template>
